import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import store from '@/admin/store';
import { editAboutUs, getAboutUs } from '@/admin/api/about';
import EntityModel from './model';
import { AboutApi, AboutApiSend } from '@/interfaces/models/about.interface';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import SiteModule from '@/admin/store/site';
import { getApiErrorMessage, showError } from '@/lib/utils/Utils';

const MODULE_NAME = 'contentAboutUs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ContentAboutUsModule extends EntityBaseModule {
    constructor(module: ContentAboutUsModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_MAIN_PHOTO(url: string) {
        this.model.mainPhoto.value = url;
    }

    @Mutation
    SET_MAIN_PHOTO_FILE(file: File | null) {
        this.model.mainPhoto.file = file;
    }

    @Mutation
    SET_MAIN_PHOTO_PREVIEW(base64String: string) {
        this.model.mainPhoto.preview = base64String;
    }

    @Mutation
    SET_MAIN_TEXT(text: string) {
        this.model.mainText.value = text;
    }

    @Mutation
    SET_LOGO_PHOTO(url: string) {
        this.model.logoPhoto.value = url;
    }

    @Mutation
    SET_LOGO_PHOTO_FILE(file: File | null) {
        this.model.logoPhoto.file = file;
    }

    @Mutation
    SET_LOGO_PHOTO_PREVIEW(base64String: string) {
        this.model.logoPhoto.preview = base64String;
    }

    @Mutation
    SET_LOGO_TEXT(text: string) {
        this.model.logoText.value = text;
    }

    @Mutation
    SET_SITE(site: string) {
        this.model.site.value = site;
    }

    @Mutation
    SET_ADDRESS(address: string) {
        this.model.address.value = address;
    }

    @Mutation
    SET_LONG(long: string) {
        this.model.long.value = long;
    }

    @Mutation
    SET_LAT(lat: string) {
        this.model.lat.value = lat;
    }

    @Action({ rawError: true })
    async getInfo() {
        this.SET_IS_LOADING(true);

        const result = await getAboutUs();
        await this.setInfo(result as AboutApi);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    setInfo(data: AboutApi) {
        if (!data) {
            return;
        }

        this.SET_MAIN_TEXT(data.mainText);
        this.SET_MAIN_PHOTO(data.image);
        this.SET_LOGO_TEXT(data.logoText);
        this.SET_LOGO_PHOTO(data.logo);
        this.SET_ADDRESS(data.address);
        this.SET_PHONE(data.phone);
        this.SET_EMAIL(data.email);
        this.SET_SITE(data.site);
        this.SET_LONG(data.long);
        this.SET_LAT(data.lat);
    }

    @Action({ rawError: true })
    updateMainText(text: string) {
        this.setValidateState({ index: 'mainText', field: this.model.mainText });
        this.SET_MAIN_TEXT(text);
    }

    @Action({ rawError: true })
    updateLogoText(text: string) {
        this.setValidateState({ index: 'logoText', field: this.model.logoText });
        this.SET_LOGO_TEXT(text);
    }

    @Action({ rawError: true })
    updateAddress(address: string) {
        this.SET_ADDRESS(address);
    }

    @Action({ rawError: true })
    updateLong(long: string) {
        this.setValidateState({ index: 'long', field: this.model.long });
        this.SET_LONG(long);
    }

    @Action({ rawError: true })
    updateLat(lat: string) {
        this.setValidateState({ index: 'lat', field: this.model.lat });
        this.SET_LAT(lat);
    }

    @Action({ rawError: true })
    updateSite(site: string) {
        this.setValidateState({ index: 'site', field: this.model.site });
        this.SET_SITE(site);
    }

    @Action({ rawError: true })
    async validate() {
        this.setValidateState({ index: 'mainText', field: this.model.mainText });
        this.setValidateState({ index: 'logoText', field: this.model.logoText });
        this.setValidateState({ index: 'address', field: this.model.address });
        this.setValidateState({ index: 'site', field: this.model.site });
        this.setValidateState({ index: 'long', field: this.model.long });
        this.setValidateState({ index: 'lat', field: this.model.lat });
        this.setValidateState({ index: 'phone', field: this.model.phone });
        this.setValidateEmail({ index: 'email', field: this.model.email });

        const fieldsWithError = await this.getFieldsWithError();

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: AboutApiSend = {
            mainText: this.model.mainText.value,
            logoText: this.model.logoText.value,
            long: this.model.long.value,
            lat: this.model.lat.value,
        };

        if (this.model.mainPhoto.file) {
            data['image'] = this.model.mainPhoto.file;
        }

        if (this.model.logoPhoto.file) {
            data['logo'] = this.model.logoPhoto.file;
        }

        if (this.model.address.value) {
            data['address'] = this.model.address.value;
        }

        if (this.model.phone.value) {
            data['phone'] = this.model.phone.value;
        }

        if (this.model.email.value) {
            data['email'] = this.model.email.value;
        }

        if (this.model.site.value) {
            data['site'] = this.model.site.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareData();
            const result = await editAboutUs(data);

            if (!result.message) {
                await this.getInfo();
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.SET_MAIN_TEXT('');
        this.SET_MAIN_PHOTO('');
        this.SET_MAIN_PHOTO_FILE(null);
        this.SET_MAIN_PHOTO_PREVIEW('');
        this.SET_LOGO_TEXT('');
        this.SET_LOGO_PHOTO('');
        this.SET_LOGO_PHOTO_FILE(null);
        this.SET_LOGO_PHOTO_PREVIEW('');
        this.SET_ADDRESS('');
        this.SET_PHONE('');
        this.SET_EMAIL('');
        this.SET_SITE('');
        this.SET_LONG('');
        this.SET_LAT('');

        SiteModule.SET_IS_BLOCK(false);
    }
}
export default getModule(ContentAboutUsModule);
