import API from './../config/api-routers';
import { makeFormRequest, makeGetRequest } from '@/api/helpers';

export const getAboutUs = async () => {
    const result = await makeGetRequest(API.about.getPage);

    return result.data.result.data.items;
};

export const editAboutUs = async (data: any) => {
    const result = await makeFormRequest(API.about.editPage, data);

    return result;
};
