
import { Vue, Component } from 'vue-property-decorator';
import AboutEntity from '@/admin/store/content/about/entity';

import Loading from '@/components/Loading.vue';

import InputDefault from '@/components/input/InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import Inputmask from 'inputmask';
import { numberRegExp } from '@/constants';
import { imageToFile } from '@/lib/utils/Utils';

@Component({
    components: {
        Loading,
        InputDefault,
        TextareaBlock,
        ImageBlock,
        BtnSubmit,
    },
})
export default class ContentAboutUsComponent extends Vue {
    get isLoading(): boolean {
        return AboutEntity.isLoading;
    }

    get model() {
        return AboutEntity.model;
    }

    async handleImage(params: { file: File; type: string }): void {
        const result = params;
        params.file = await imageToFile(params.file);

        AboutEntity.updatePhotoFileByType(result);
    }

    removePreview(type: string): void {
        AboutEntity.removeImagePreviewByType(type);
    }

    updateMainText(text: string): void {
        AboutEntity.updateMainText(text);
    }

    updateLogoText(text: string): void {
        AboutEntity.updateLogoText(text);
    }

    updateAddress(address: string): void {
        AboutEntity.updateAddress(address);
    }

    updatePhone(phone: string): void {
        AboutEntity.updatePhone(phone);
    }

    updateEmail(email: string): void {
        AboutEntity.updateEmail(email);
    }

    updateSite(site: string): void {
        AboutEntity.updateSite(site);
    }

    updateLong(long: string): void {
        AboutEntity.updateLong(long);
    }

    updateLat(lat: string): void {
        AboutEntity.updateLat(lat);
    }

    send(): void {
        AboutEntity.sendEdit();
    }

    created(): void {
        AboutEntity.getInfo();
    }

    mounted(): void {
        const settingsMask = new Inputmask({ mask: '[-]9{1,2}.9{6}', showMaskOnHover: false });
        const inputsCoords = this.$el.getElementsByClassName('j_coords_mask_wrapper');

        for (const item of Object.values(inputsCoords)) {
            const input = item.getElementsByTagName('input');
            Inputmask.remove(input);
            settingsMask.mask(input);
        }

        const NumberMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            NumberMask.mask(item.getElementsByTagName('input')[0]);
        }

        const EmailMask = new Inputmask(this.model.email.mask);
        const inputsEmail = this.$el.getElementsByClassName('j_email_mask_wrapper');

        for (const item of Object.values(inputsEmail)) {
            EmailMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    beforeDestroy(): void {
        AboutEntity.reset();
    }
}
