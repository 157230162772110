import InputEntity from '@/lib/formFactory/inputEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import { AboutModel } from '@/interfaces/models/about.interface';

export default class EntityModel {
    model: AboutModel;

    constructor() {
        const MainPhotoEntity = new ImageInputEntity('mainPhoto');
        MainPhotoEntity.setTitle('Заглавное фото');

        const MainTextEntity = new InputEntity('mainText');
        MainTextEntity.setRequired(true);
        MainTextEntity.setSize(4000);

        const LogoPhotoEntity = new ImageInputEntity('logoPhoto');
        LogoPhotoEntity.setTitle('Логотип');

        const LogoTextEntity = new InputEntity('logoText');
        LogoTextEntity.setRequired(true);
        LogoTextEntity.setSize(4000);

        const AddressEntity = new InputEntity('address');
        AddressEntity.setTitle('Адрес');
        AddressEntity.setRequired(true);

        const PhoneEntity = new InputEntity('phone');
        PhoneEntity.setTitle('Телефон');
        PhoneEntity.setRequired(true);

        const EmailEntity = new InputEntity('email');
        EmailEntity.setTitle('Email');
        EmailEntity.setMask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,20}][.*{1,20}]',
            greedy: false,
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
                    casing: 'lower',
                },
            },
        });
        EmailEntity.setValidation({
            empty: 'Поле не заполнено',
            regex: 'Неверный формат email',
        });
        EmailEntity.setRequired(true);

        const SiteEntity = new InputEntity('site');
        SiteEntity.setTitle('Сайт');
        SiteEntity.setRequired(true);

        const LongEntity = new InputEntity('long');
        LongEntity.setTitle('Долгота');
        LongEntity.setRequired(true);

        const LatEntity = new InputEntity('lat');
        LatEntity.setTitle('Широта');
        LatEntity.setRequired(true);

        this.model = {
            mainPhoto: MainPhotoEntity.entity[MainPhotoEntity.entityName],
            mainText: MainTextEntity.entity[MainTextEntity.entityName],
            logoPhoto: LogoPhotoEntity.entity[LogoPhotoEntity.entityName],
            logoText: LogoTextEntity.entity[LogoTextEntity.entityName],
            address: AddressEntity.entity[AddressEntity.entityName],
            phone: PhoneEntity.entity[PhoneEntity.entityName],
            email: EmailEntity.entity[EmailEntity.entityName],
            site: SiteEntity.entity[SiteEntity.entityName],
            long: LongEntity.entity[LongEntity.entityName],
            lat: LatEntity.entity[LatEntity.entityName],
        };
    }
}
